import { PoolUpdater, ProtocolUpdater, TokenUpdater } from 'state/info/updaters'
import NotFound from 'views/NotFound'

export const InfoPageLayout = ({ children }) => {
  return (
    <>
      <ProtocolUpdater />
      <PoolUpdater />
      <TokenUpdater />
      {children}
      {/* <NotFound /> */}
    </>
  )
}
